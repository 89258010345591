.home_intro_container {
  width: 70% !important;
}

.home_intro_title {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 1.4;
  text-align: center;
  padding: 30px;
  padding-top: 64px;
  color: #000;
}

.home_intro_para {
  font-family: 'Manrope', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 1.4;
  color: #000;
  text-align: center;
  letter-spacing: 0.03em;
}

.gallary_main_div {
  margin-top: 70px;
  /* border: 1px solid red; */
  padding: 0px;
}

.gallary_item_1 {
  margin-top: 90px;
  padding: 35px 35px !important;
  text-align: justify;
  width: 300px !important;
  color: #fff;
  background-color: #ff770a;
}

.gallary_para {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 1.4;
}

.gallary_btn {
  width: 100%;
  height: 40px;
  background-color: #fff;
  color: #ff770a;
  border: 1px solid #ff770a;
  border-radius: 5px;
}

.gallary_item_2 {
  width: 300px;
  height: 330px;
}

.gallary_img_2 {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.gallary_item_3 {
  width: 300px;
  height: 330px;
  margin-top: 90px;
}

.gallary_img_3 {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.gallary_item_4 {
  width: 300px;
  height: 330px;
}
.gallary_img_4 {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.gallary_item_5 {
  width: 300px;
  height: 330px;
  margin-top: -210px;
}
.gallary_img_5 {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.gallary_item_6 {
  margin-top: -115px;
  padding: 35px 35px !important;
  text-align: justify;
  width: 300px !important;
  color: #fff;
  background-color: #1bc2df;
}

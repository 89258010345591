.header_items {
  display: flex;
  justify-content: space-between;
}

.modal_header_title {
  position: relative;
}

.close_btn_icon {
  position: absolute;
  left: 400px;
  font-weight: 800;
  cursor: pointer;
}

.modal_img {
  width: 150px;
  height: 30px;
  margin: auto;
  margin-top: 30px;
  text-align: center;
  object-fit: contain;
}
.body_title {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  text-align: center;
  color: #000000;
  margin-bottom: 10px;
}

.body_para {
  font-size: 13px;
  font-weight: 400;
  line-height: 1.5;
  color: #000000;
  margin: 0;
  padding: 20px;
}

.modal_header_title {
  margin: auto !important;
  padding-bottom: 10px;
  border-bottom: 2px solid #e5e7eb;
}

.bold_title {
  font-weight: 600;
}

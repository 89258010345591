.form_label {
  /* font-size: 14px !important;
  color: #6b7280;
  line-height: 40px;
  text-align: left !important; */

  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  margin-top: 25px;
  /* identical to box height, or 143% */

  display: flex;
  align-items: center;

  /* Colors/Gray/500 */

  color: #6b7280;
}

.form_errors {
  color: #ff4073 !important;
  font-size: 14px;
  line-height: 20px;
}

.form_fields:focus {
  outline: none !important;
  box-shadow: none !important;
}

/* Styling Checkbox Field */

.form_checkbox_main_div {
  padding-left: 0px !important;
}

.form_checkbox_field {
  color: #3369d4 !important;
  width: 20px;
  height: 20px;
  /* margin-top: 0px; */
  /* padding-left: 0px !important; */
}

.hover_section_img_div {
  width: 365px;
  height: 300px;
  position: relative;
  overflow: hidden;
  transition: all 0.5s ease-in-out;
}

.hover_section_img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: all 0.8s ease;
  overflow: hidden;
}

.hover_items {
  position: absolute;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  top: 0%;
  left: 0%;
  width: inherit;
  height: inherit;
  transition: all 0.4 ease-in-out;
  visibility: hidden;
  color: #fff;
  font-family: "Manrope", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 140%;
  text-align: center;
  padding: 50px;
}

.hover_section_img_div:hover .hover_items {
  visibility: visible;
  overflow: hidden;
  background-color: rgba(255, 119, 10, 0.5);
}

.hover_section_img_div:hover .hover_section_img {
  transform: scale(1.1);
}

.hover_section_img:hover {
  transform: scale(1.1);
  transform: skew(4);
}

.hover_icon {
  font-size: 40px;
  opacity: 0.7;
}

.hover_title {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 26px;
  line-height: 40px;
}

.hover_address {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 40px;
}

.hover_para {
  width: 86%;
  text-align: justify;
}

.hover_price {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 36px;
}

.kyc_container_fluid {
  /* margin-top: 80px; */
  background-color: #f9fafb;
}

.kyc_form_div {
  padding-top: 100px;
  text-align: left;
}

.input_field_div_half {
  width: 70%;
}

.kyc_photo_div {
  display: flex;
  /* justify-content: center; */
  align-items: center;
  justify-content: space-around;
  gap: 1px;
}

.upload_file_div {
  width: 100px !important;
  height: 250px;
  border: 2px solid grey;
  /* overflow: hidden; */
}

.pp_image {
  width: 100%;
  height: 80%;
  object-fit: cover;
}

.citizenship_image {
  width: 100%;
  height: 80%;
  object-fit: cover;
}

.input_field_div {
  width: 100%;
}

/* Styling kyc forms */

.kyc_title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  color: #004a57;
}

.personal_detail_title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  /* identical to box height, or 140% */
  margin-top: 40px;
  /* Colors/Secondary/900 */

  color: #004a57;
}

.gender_title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  margin-top: 20px;
  line-height: 20px;
  /* identical to box height, or 143% */

  display: flex;
  align-items: center;

  /* Colors/Gray/500 */

  color: #6b7280;
}

/* Styling Calendar Field */

.calendar_field_label {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */

  display: flex;
  align-items: center;

  /* Colors/Gray/500 */
  margin-top: 24px;

  color: #6b7280;
}

.calendar_field {
  width: 100%;
  height: 40px;
  border: 1px solid #6b7280;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 10px;
}

.gender_fields .radio_div_label {
  color: rgb(139, 139, 139) !important;
}

.education_detail_title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  /* identical to box height, or 140% */
  margin-top: 40px;
  /* Colors/Secondary/900 */

  color: #004a57;
}

.photo_label {
  text-align: left !important;
  color: #6b7280;
}

.save_change_btn {
  background-color: #ff770a !important;
  color: #fff !important;
  outline: none !important;
  margin-top: 20px !important;
  margin-bottom: 50px !important;
  font-family: "Inter", sans-serif;
  width: 25%;
}

.interest_div {
  margin-top: 100px;
}

.interest_card {
  width: 100px;
  height: 100px;
  margin: auto;
}

.interest_column {
  padding-bottom: 80px;
}

.interest_img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
}

.interest_container {
  margin-top: 50px;
  margin-bottom: 100px;
}

/* .timeline_container_fluid {
  margin-top: 80px;
  background-color: #f9fafb;
} */

.timeline_row {
  padding-bottom: 200px;
}

.timeline_div {
  padding-top: 100px;

  text-align: left;
}

.timeline_history_div {
  background: #ffffff;
  width: 83% !important;
  height: 75px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* justify-content: center; */
  border: 1px solid #d1d5db;
  border-left: 5px solid #ff770a;
  border-radius: 10px;
  padding-left: 35px;
  padding-right: 35px;
  padding-top: 15px;

  margin-top: 30px;
}

.timeline_history_title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  /* line-height: 32px; */
  margin-bottom: 20px;
  color: #004a57;
}

.timeline_history_para {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  padding-top: 10px;
  color: #000000;
}

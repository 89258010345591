.copyright {
  background-color: #f3f4f6;
}
.footer_copyright_text {
  color: #4b5563;
  font-family: "Inter", sans-serif;
  text-align: center;
  padding-top: 15px;
  padding-bottom: 15px;
  opacity: 0.7;
}
.footer_right {
  text-align: right;
}

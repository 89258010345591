.checkbox_div_label {
  display: flex;
}

.checkbox_label {
  height: 24px;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: #000000;
}

.checkbox_input {
  width: 24px;
  height: 24px;
  accent-color: #ff770a;
  color: aliceblue !important;
  /* background: #ff770a !important; */
}
/* 
.checkbox_input:checked {
  background: #ff770a !important;
} */

/* input[type="checkbox"]::-ms-check {
  display: none;
}

input[type="checkbox"] {
  --webkit-appearance: none;
  --moz-appearance: none;
}
input[type="checkbox"]:checked {
  background: #ff770a !important;
} */

/* .checkbox_label:before {
  background-color: red !important;
} */
/* 
.checkbox_div_label .checkbox_input:checked ~ .checkbox_label::before {
  background-color: black !important;
} */

/* .custom_checkbox > [type="checkbox"]:not(:checked) + label:before,
.custom_checkbox > [type="checkbox"]:checked + label:before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  margin-top: -9px;
  width: 17px;
  height: 17px;
  border: 1px solid #ddd;
  background: #d10000;
  border-radius: 2px;
} */

.loader_div {
  /* margin-top: 100px; */
  /* margin-left: 150px; */
  /* width: 100%; */
  /* border: 3px solid red; */
}

.wrapper_bar {
  width: 100px !important;
  height: 100px;
}

.radio_div_label {
  display: flex;
}

.radio_label {
  height: 24px;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: #000000;
}

.radio_input {
  width: 24px;
  height: 24px;
  background: #ff770a !important;
}

.home_carousel_div {
  width: 100%;
  height: 756px;
}

.home_carousel_img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.signup_btn {
  border: 1px solid #ff770a !important;
  background-color: #ff770a !important;
  padding: 6px 12px !important ;
  cursor: pointer;
  color: #fff !important;
}
.nav_item_signup {
  color: #fff !important;
  font-weight: 700;
  cursor: pointer;
}

.nav_items {
  margin-left: auto !important;
  margin-right: 50px !important;
}

.ghumfir_logo {
  margin-left: 50px;
  height: 34px;
  width: 125px;
}

.nav_login_item {
  color: #ff770a !important;
  font-weight: 700;
  margin-right: 20px !important;
}

.navbar_conatiner_div {
  display: block !important;
}

.sign_in_icon {
  font-size: 32px;
}

.home_carousel_div {
  position: relative;
}

.small_tag {
  padding-right: 10px;
}

/* Styling Navbar */
.header_navbar {
  /* background-color: #fff !important; */
  background: transparent !important;
  padding: 0px !important;
  margin: 0px !important;
  border: none !important;
  border-radius: 0px !important;
  height: 80px;
  box-shadow: none !important;
}

.header_navbar_active {
  background-color: rgb(252, 252, 252) !important;
  padding: 0px !important;
  margin: 0px !important;
  border: none !important;
  height: 66px;
  border-radius: 0px !important;
  box-shadow: 0px 4px 3px rgba(0, 0, 0, 0.04),
    0px 10px 8px rgba(151, 151, 151, 0.1) !important;
  /* z-index: 9999999; */
}

/* After Login Styling Navbar*/

/* .login_dropdown_btn {
  cursor: pointer;
} */

.login_dropdown_btn button {
  background-color: transparent !important;
  color: #fff !important;
  border: none !important;
  outline: none !important;
  padding: 6px 12px !important;
}

.login_dropdown_btn button:focus {
  outline: none !important;
  box-shadow: none !important;
}

.nav_item_login {
  margin-left: 15px;
  color: #fff !important;
}

.nav_item_login :hover {
  color: #ff770a !important;
  text-decoration: none !important;
}

.nav_item_login_text {
  color: #fff !important;
  margin-right: 20px;
  margin-left: 15px !important;

  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
}
.nav_link_match_item {
  padding: 15px 12px !important;
}

.nav_item_match_text {
  color: #fff !important;
  margin-right: 20px;
  margin-left: 15px !important;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
}

.nav_item_login_match_text {
  color: #000 !important;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
}

.nav_item_match_text :hover {
  text-decoration: none !important;
}

.user_icon {
  border-radius: 50%;
  font-size: 32px;
  /* background: black; */
  /* border: 1px solid white; */
  /* color: black !important; */
}

.header_navbar_active .user_icon {
  border-radius: 50%;
  font-size: 32px;
  background: rgb(0, 0, 0);
  /* border: 1px solid white; */
  color: black !important;
}

.header_navbar_active .nav_item_login_text {
  color: #000 !important;
  font-weight: 700;
  margin-left: 15px !important;
}

.header_navbar_active .nav_item_match_text {
  color: #000 !important;
  font-weight: 700;
}



.my_account_icon {
  font-size: 32px;
  padding: 0px !important;
  color: #000000 !important;
}

.logout_icon {
  font-size: 32px;
  color: #000000 !important;
}

.dropdown_items {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}

.logout_text {
  color: #000 !important;
  padding: 5px;
}

.my_account_text {
  color: #000 !important;
  padding: 5px;
}

.my_account_text :hover {
  text-decoration: none !important;
  color: #000 !important;
}

.nav_item_login_span {
  color: #000 !important;
  margin-right: 20px;
  margin-left: 15px !important;

  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
}

.forgot_main_div {
  margin-top: 150px;
  margin-bottom: 100px;
}

.forgot_col_left {
  padding-top: 30px;
  padding-bottom: 50px;
  text-align: left;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.forgot_icon_title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 28px;
  display: flex;
  align-items: center;
  color: #1f2937;
}

.forgot_title {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  color: #1f2937;
}

.forgot_para {
  width: 478px;
  height: 48px;

  /* Typography/Font Sans/Regular/Base */

  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  color: #374151;
}

.forgot_img_div {
  width: 100%;
  height: 500px;
}

.forgot_img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.forgot_btn {
  margin-top: 30px;
  border: 1px solid #ff770a;
  width: 100%;
  background: #ff770a !important;
  border-radius: 5px;
  height: 48px;
  color: #fff !important;
}

.create_title {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  color: #1f2937;
}

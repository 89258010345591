.header_items {
  display: flex;
  justify-content: space-between;
}

.modal_header_title {
  position: relative;
}

.close_btn_icon {
  position: absolute;
  left: 400px;
  font-weight: 800;
  cursor: pointer;
}

/* .modal_img {
    width: 150px;
    height: 30px;
    margin: auto;
    margin-top: 30px;
    text-align: center;
    object-fit: contain;
  } */
.body_title {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  text-align: center;
  color: #000000;
  margin-bottom: 10px;
}

.body_para {
  font-size: 13px;
  font-weight: 400;
  line-height: 1.5;
  color: #000000;
  margin: 0;
  padding: 20px;
}

.modal_header_title {
  margin: auto !important;
  padding-bottom: 10px;
}

.bold_title {
  font-weight: 600;
}

.trip_modal_header_title {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 36px;
  line-height: 40px;
  margin-top: 10px;
  color: #ff770a;
  /* padding-top: 20px; */
}

.close_trip_btn_icon {
  position: absolute;
  left: 400px;
  top: 10px;
  font-weight: 800;
  cursor: pointer;
}

.trip_footer_btn {
  width: 239px;
  height: 48px;
  left: 454px;
  bottom: 32px;
  background-color: #ff770a;
  border-radius: 5px;
  color: #fff;
  border: 1px solid #ff770a;
}

.trip_footer_btn_div :hover {
  background-color: #1bc2df !important;
  border: 1px solid #1bc2df !important;
  color: #fff;
  transition: all 0.5s ease;
}

.trip_modal_footer_div {
  height: 25px;
}

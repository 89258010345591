/* .signup_main_div {
  background-color: #f5f5f5;
} */

.navbar_signup {
  background-color: #fdfdfd !important;
  height: 63px;
  border-bottom: 1px solid #e7e7e7;
  /* position: fixed; */
  /* z-index: -9999; */
}

.signup_container {
  margin-top: 30px;
}

.right_col_img_div {
  width: 90%;
  height: 700px;
}

.right_col_img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* Styling Form Field */

.form_title {
  font-style: normal;
  font-weight: 600;
  font-size: 36px;
  line-height: 40px;
  text-align: left;
  text-transform: uppercase;
  margin-left: 50px;
}

.form_div {
  margin: 0px 52px;
  text-align: left;
}

.checkbox_div {
  margin-top: 20px;
  font-weight: 400;
}

.form_checkbox {
  background-color: #c1a200 !important;
}

.terms_condition {
  color: #c1a200;
  font-family: "Manrope", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
}

.terms_condition:hover {
  color: #c1a200;
}

.submit_btn {
  background-color: #ff770a !important;
  color: #fff !important;
  outline: none !important;
  margin-top: 20px !important;
  margin-bottom: 20px !important;
  width: 100%;
}

.submit_btn:focus {
  outline: none !important;
  box-shadow: none !important;
}

.form_radio_btn_div {
  display: flex;
}

.gender_label {
  margin-top: 10px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #6b7280;
}

.form_radio_btn_div input[type="radio"] {
  padding: 1px !important;
  height: 25px;
  margin-top: 20px;
  /* border: 1px solid #ff770a !important; */
  /* background: #fff !important; */
  /* accent-color: #ff770a; */
  background-color: rgb(233, 44, 44) !important;
  /* color: ; */
  /* color: #fdfdfd; */

  margin-left: -100px;
}

/* .checkbox_div {
}

.checkbox_div input[type="checkbox"] {
  padding: 1px !important;
  height: 25px;
  margin-top: 10px;
  border: 1px solid #ff770a !important;
  background: #fff !important;
  accent-color: #ff770a;
} */

.form_checkbox_div {
  /* margin-left: 20px; */
  display: flex !important;
  gap: 20px;
  margin-top: 30px;
  text-align: left !important;
  flex-direction: column;
}

.form_checkbox_div input[type="checkbox"] {
  accent-color: #ff770a;
  /* align-content: center; */
}


.form__signup_label {
  margin-left: 30px;
}

.form_checkbox_div_signup{
  padding-left: 0px !important;
}
.interest_container_fluid {
  /* margin-top: 80px; */
  background-color: #f9fafb;
}

.interest_col_div {
  text-align: left;
  padding-top: 100px;
}

.interest_div {
  text-align: left;
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.interest_title {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  color: #004a57;
}

/* Styling Interest Fields */

.interest_field_div {
  display: flex;
  gap: 20px;
}

.interest_checkbox {
  margin-right: 10px;
  padding: 10px !important;
}

/* Styling Gender Section */

.interest_gender_title {
  margin-top: 40px;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  color: #004a57;
  margin-bottom: 30px;
}

.gender_subtitle {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  margin-left: 8px;
  color: #6b7280;
}

/* Styling Interest Selection Section */

.interest_selection_title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  color: #004a57;
  margin-bottom: 30px;
}

/* Styling Budget Section */

.interest_budget_title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  color: #004a57;
  margin-bottom: 30px;
}

/* Styling Age Group Section */

.interest_age_title {
  width: 191px;
  height: 28px;
  margin-bottom: 30px;

  /* Text/Heading/Semi-Bold/XL */

  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  color: #004a57;
}

/* Styling Button Section */

.save_change_btn {
  background-color: #ff770a !important;
  color: #fff !important;
  outline: none !important;
  margin-top: 20px !important;
  margin-bottom: 50px !important;
  width: 25%;
}

.interest_fields .checkbox_label .checkbox_input {
  background-color: #ff770a !important;
}

.trip_footer_btn_div {
  position: relative;
  /* padding: 30px; */
}

.trip_footer_btn {
  width: 239px;
  height: 48px;
  right: 350px;
  bottom: 35px;
  background-color: #ff770a;
  border-radius: 5px;
  color: #fff;
  border: 1px solid #ff770a;
  position: fixed;
}

.trip_footer_btn_div :hover {
  background-color: #1bc2df !important;
  border: 1px solid #1bc2df !important;
  color: #fff;
  transition: all 0.5s ease;
}

/* .home_banner_section_container {
  margin-bottom: 0px !important;
} */

.banner_left {
  margin-top: 60px;
  padding-left: 80px !important;
}

.banner_container_div {
  display: flex;
  flex-direction: column;
}

.banner_img_div {
  width: 150px;
  height: 80px;
  display: flex;
  gap: 15px;
}

.banner_img_1 {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.banner_img_2 {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.download_text {
  font-family: "Manrope", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 0px;
  text-align: justify;
  letter-spacing: 0.02em;
  color: #f9fafb;
}

.download_access_text {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 1.4;
  color: #ffffff;
  text-align: justify;
}

.banner_container_div {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

/* .banner_right {
  border: 3px solid red;
} */

.banner_right {
  width: 1100px !important;
  /* width: 100% !important; */
  height: 340px;
  padding-top: 0%;
  /* border: 3px solid red; */
  padding: 0px !important;
  overflow: hidden;
}

.banner_right_img {
  width: 100%;
  height: 100%;
  /* border-radius: 40px; */
  object-fit: cover;
}

.show_recommend_form_div {
  display: flex;
  flex-direction: column;
  background-color: #f9fafb;
  padding-top: 100px;
  padding-bottom: 100px;
  text-align: left;
}

.show_recommend_title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  color: #004a57;
}

/* .show_recommend_title{

  } */

.load_more_btn_div {
  /* border: 1px solid red; */
  /* width: 300px; */
  /* height: 100px; */
  margin: auto;
}

.load_more_btn_ {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;

  color: #1f2937;
}

.see_more_icons {
  margin-left: 10px;
  text-align: center;
}

.carousel_item_div {
  width: 100%;
  height: 756px;
}

.carousel_item_img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: 0.9 !important;
  /* background-color: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
    linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)) !important; */
}

.page_not_found_div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
}

.page_not_found_img {
  width: 350px;
  height: 350px;
  object-fit: cover;
}

.page_oops {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 40px;
  margin-top: 20px;
  color: black;
}

.page_wrong {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 400 !important;
  font-size: 18px;
  line-height: 150%;
  color: #6b7276;
}

.page_home_btn {
  padding: 10px 60px !important;
  background: #ff770a !important;
  border: 1px solid #ff770a !important;
  border-radius: 5px;
  margin-top: 20px;
}

.ghumfir_logo {
  margin-top: 50px;
  margin-bottom: 20px;
  height: 34px;
  width: 125px;
}

.img_div_1 {
  width: 100%;
  height: 350px;
  position: relative;
  margin-top: 40px;
  overflow: hidden;
  transition: all 0.5s ease-in-out;
}

.place_img_1 {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: all 0.8s ease;
  overflow: hidden;
  /* background: rgba(255, 119, 10, 0.5); */
}

.hover_items {
  position: absolute;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  top: 0%;
  left: 0%;
  width: inherit;
  height: inherit;
  transition: all 0.4 ease-in-out;
  visibility: hidden;
  color: #fff;
  font-family: "Manrope", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 140%;
  text-align: center;
}

.img_div_1:hover .hover_items {
  visibility: visible;
  overflow: hidden;
  background-color: rgba(255, 119, 10, 0.5);
}

.img_div_1:hover .place_img_1 {
  transform: scale(1.1);
}

.place_img_1:hover {
  transform: scale(1.1);
  transform: skew(4);
}

.hover_icon {
  font-size: 40px;
}

.hover_title {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 26px;
  line-height: 40px;
}
.hover_para {
  width: 86%;
  text-align: justify;
}

.img_div_2 {
  width: 100%;
  height: 350px;
  position: relative;
  margin-top: 40px !important;
  overflow: hidden;
  transition: all 0.5s ease-in-out;
}

.place_img_2 {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: all 0.8s ease;
  overflow: hidden;
}

.img_div_2:hover .hover_items {
  visibility: visible;
  overflow: hidden;
  background-color: rgba(255, 119, 10, 0.5);
}

.img_div_2:hover .place_img_2 {
  transform: scale(1.1);
}

.place_img_2:hover {
  transform: scale(1.1);
  transform: skew(5);
}

.common_img_div {
  width: 100%;
  height: 350px;
  position: relative;
  margin-top: 20px;
  overflow: hidden;
  transition: all 0.5s ease-in-out;
}

.common_img_div:hover .hover_items {
  visibility: visible;
  overflow: hidden;
  background-color: rgba(255, 119, 10, 0.5);
}

.common_img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: all 0.8s ease;
  overflow: hidden;
}

.common_img:hover {
  transform: scale(1.1);
  transform: skew(4);
}

.common_img_div:hover .common_img {
  transform: scale(1.1);
}

/* Styling Third Row */

.img_div_3 {
  width: 90%;
  height: 350px;
  position: relative;
  margin-top: 20px;
  overflow: hidden;
  transition: all 0.5s ease-in-out;
}

.place_img_3 {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: all 0.8s ease;
  overflow: hidden;
}

.img_div_3:hover .hover_items {
  visibility: visible;
  overflow: hidden;
  background-color: rgba(255, 119, 10, 0.5);
}

.img_div_3:hover .place_img_3 {
  transform: scale(1.1);
}

.place_img_3:hover {
  transform: scale(1.1);
  transform: skew(4);
}

.img_div_4 {
  width: 100%;
  height: 350px;
  position: relative;
  margin-top: 20px;
  overflow: hidden;
  transition: all 0.5s ease-in-out;
}
.place_img_4 {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: all 0.8s ease;
  overflow: hidden;
}

.img_div_4:hover .hover_items {
  visibility: visible;
  overflow: hidden;
  background-color: rgba(255, 119, 10, 0.5);
}

.img_div_4:hover .place_img_4 {
  transform: scale(1.1);
}

.place_img_4:hover {
  transform: scale(1.1);
  transform: skew(4);
}

/* .login_main_div {
  background-color: #f5f5f5;
} */

.navbar_login {
  background-color: #fdfdfd !important;
  height: 63px;
  border-bottom: 1px solid #e7e7e7;
}

.navbar_login {
  background-color: #fdfdfd !important;
  height: 63px;
  /* border-bottom: 1px solid #e7e7e7; */
  /* position: fixed; */
  /* z-index: -9999; */
}

.right_col_img_div {
  width: 90%;
  height: 700px;
}

.right_col_img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.login_container {
  margin-top: 30px;
}

/* Styling Form Field */

.form_title {
  font-style: normal;
  font-weight: 600;
  font-size: 36px;
  line-height: 40px;
  text-align: left;
  margin-left: 50px;
  text-transform: uppercase;
}

.form_div {
  margin: 0px 52px;
  text-align: left;
}

.forgot_pass_div {
  margin-top: 35px;
  margin-bottom: 0px !important;
  padding-bottom: 0%;
  font-weight: 400;
  text-align: right !important;
}

.form_checkbox {
  padding: 8px !important;
}

.remember_me {
  color: #6b7280;
}
.forgot_password {
  color: #6b7280;
  /* margin-right: 100px !important; */
}

.submit_btn {
  background-color: #ff770a !important;
  color: #fff !important;
  outline: none !important;
  margin-top: 10px !important;
  margin-bottom: 20px !important;
  width: 100%;
}

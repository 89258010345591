.title_div {
  position: relative;
  z-index: 111;
  margin-top: 160px;
  margin-bottom: 40px;
}

.title {
  position: absolute;
  left: 7%;
  bottom: 5%;
  text-transform: uppercase;
  z-index: -11;
  font-size: 90px;
  font-family: "Inter", sans-serif;
  text-shadow: 0px 4px 3px rgba(0, 0, 0, 0.04), 0px 10px 8px rgba(0, 0, 0, 0.1);
  font-weight: 800;
  color: #fff1e6;
}

.subtitle {
  position: absolute;
  text-transform: uppercase;
  left: 34%;
  font-weight: 700;
  font-size: 40px;
  bottom: 5px;
  z-index: 111;
  color: #000000;
  font-family: "Inter", sans-serif;
  font-style: normal;
}

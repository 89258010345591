.project_title {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 40px;
  line-height: 150%;
  color: #000000;
}

.home_project_section_container {
  padding: 50px;
  padding-bottom: 60px;
}

/* Styling Project UI */

.project_image_div {
  width: 300px;
  height: 300px;
}
.project_img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.account_page_div {
  display: flex;
  flex-direction: column;
  /* flex-grow: 3 1; */
  /* padding-left : 0px !important; */
  padding-top: 80px;
  padding-right: 0px;

  background-color: #f9fafb;
}

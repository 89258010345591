.email_sent_div {
  margin-top: 150px;
  margin-bottom: 150px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 30px;
}

.email_sent_img {
  width: 200px;
  height: 100%;
  object-fit: contain;
  margin-left: 100px;
}

.email_sent_h1 {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  width: 456px;
  height: 28px;
  /* margin-left: 10px; */
  /* identical to box height, or 140% */

  /* Colors/Primary/500 */

  color: #ff770a;
}

.email_sent_para {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  width: 623px;
  height: 48px;
  /* or 150% */

  text-align: center;

  /* Colors/Neutral/Black */

  color: #000000;
}

.card_div {
  background-color: #fff1e6;
  border: 1px solid #ffd9ba;
  border-radius: 10px;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.05);
  padding-bottom: 80px;
  padding-top: 20px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 280px;
  height: 320px;
}

.user_img_div {
  width: 70px;
  height: 70px;
  margin: auto;
}

.user_img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
}

.user_name {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  padding-top: 10px;
  line-height: 28px;
  color: #000000;
}

.user_email {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */

  /* Colors/Gray/800 */

  color: #1f2937;
}

.user_address {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #1f2937;
}

.user_age {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */

  /* Colors/Gray/800 */

  color: #1f2937;
}

.user_btn_div {
  width: 100%;
  text-align: center;
  margin: auto;
}

.submit_btn {
  background-color: #ff770a !important;
  border: 1px solid #ff770a !important;
  border-radius: 10px;
  padding: 10px;

  margin-bottom: 20px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  width: 80%;
  color: #fff;
  border: none;
  cursor: pointer;
}

.submit_btn :hover {
  background-color: #a8a8a8 !important;
}

.security_container_fluid {
  margin-top: 100px;
  background-color: #f9fafb;
  text-align: left;
}

.security_div {
  /* padding-top: 150px; */
  padding-bottom: 100px;
}

.interest_security_title {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  margin-bottom: 40px;
  color: #004a57;
}

.input_field_div {
  width: 70%;
}

.save_change_btn {
  background-color: #ff770a !important;
  color: #fff !important;
  outline: none !important;
  margin-top: 20px !important;
  margin-bottom: 50px !important;
  font-family: "Inter", sans-serif;
  width: 25%;
}

.interest_security_bottom_title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  /* identical to box height, or 133% */

  /* Colors/Secondary/900 */

  color: #004a57;
}

.interest_sure_para {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */

  display: flex;
  align-items: center;

  /* Colors/Gray/500 */

  color: #6b7280;
}

.deactivate_btn {
  background-color: #ff1655 !important;
  color: #fff !important;
  outline: none !important;
  margin-top: 20px !important;
  margin-bottom: 50px !important;
  font-family: "Inter", sans-serif;
  width: 25%;
}

.email_verification_div {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 150px;
  margin-bottom: 200px;
}

.email_verification_img {
  width: 200px;
  height: 100%;
  object-fit: contain;
}

.verication_title {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 600;
  margin-top: 10px;
  font-size: 16px;
  line-height: 32px;
  color: #000000;
  opacity: 0.8;
}

.verication_text {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 28px;
  color: #356a46;
  margin-top: 20px;
  margin-bottom: 5px !important;
}

.ghumfir_logo {
  width: 100px;
  height: 100%;
  margin-top: 10px;
}

.next_btn {
  width: 200px;
  background-color: #ff770a !important;
  border-radius: 5px;
  color: #ffffff !important;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 32px;
  text-align: center;
  margin-top: 20px;
  margin-bottom: 20px;
}
.next_btn:focus {
  outline: none !important;
  box-shadow: none !important;
}

.side_bar_link {
  display: block;
  position: relative;
  padding: 0.5rem 1rem;
  color: #ff770a;
  text-decoration: none;
  font-size: 1rem;
  font-weight: 500;
  transition: all 0.3s ease-in-out;
  border-radius: 0.25rem;
  margin-bottom: 0.5rem;

  /* width: 161px;
  height: 24px;
  padding: 0.5rem 1rem;
  margin-bottom: 0.5rem;

  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;


  color: #ff770a; */
}

.active {
  background-color: #bdbdbd !important;
  color: #fff !important;
}
.active:hover {
  background-color: #bdbdbd !important;
  color: #fff !important;
  text-decoration: none !important;
}

.side_bar_div {
  position: relative;
}

.side_bar_title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  position: fixed;
  color: #004a57;
  padding-left: 10px;
  margin-top: 60px;
}

.sidebar_list {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 30px;
  padding-top: 100px;
  position: fixed;
}

.side_bar_icon {
  margin-right: 15px;
  font-size: 20px;
}

.sidebar_list_items :hover {
  color: #ff770a !important;
  background-color: #e5e7eb;
}

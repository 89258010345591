
.footer_container_fluid {
  border-top: 1px solid rgb(235, 235, 235);
  background-color: #f3f4f6;
}

.footer_container {
  margin-top: 40px;
}

.footer_title {
  font-family: "Manrope", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #ff770a;
  text-align: left !important;
}
ul,
li {
  font-family: "Manrope", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 1.8;
  color: #6b7280;
  letter-spacing: 0.04em;
  text-align: left !important;
}
li {
  list-style: none;
}
.social_icon {
  font-size: 25px;
  color: rgb(247, 247, 247);
}
.icon_header {
  opacity: 0.7;
  font-size: 18px;
  color: rgb(241 157 57);
}
.map {
  width: 100%;
  height: 150px;
}

.footer_icon {
  color: #ffc08e;
  font-size: 21px;
  margin-right: 8px;
}

@media only screen and (max-width: 600px) {
  .footer_title {
    text-align: center;
  }

  ul,
  li {
    text-align: center;
  }

  .map_div {
    text-align: center;
  }
}

@media only screen and (max-width: 768px) {
  .footer_title {
    text-align: center;
  }

  .map_div {
    text-align: center;
  }

  li {
    text-align: center;
  }
}

.slick-prev:before,
.slick-next:before {
  font-family: "slick";
  font-size: 40px !important;
  line-height: 0 !important;
  opacity: 1 !important;
  color: #ff9036 !important;
}

/* .slick-next {
  right: 32% !important;
} */

.slick-prev {
  left: -4% !important;
  z-index: 111;
}

/* Styling Home Carousel Section */

.home_carousel_div {
  width: 100%;
  height: 756px;
}

.home_carousel_img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.signup_btn {
  border: 1px solid #ff770a !important;
  background-color: #ff770a !important;
  padding: 6px 12px !important ;
}
.nav_item_signup {
  color: #fff !important;
  font-weight: 700;
}

.nav_items {
  margin-left: auto !important;
  margin-right: 50px !important;
}

.ghumfir_logo {
  margin-left: 50px;
}

.nav_item_login {
  color: #ff770a !important;
  font-weight: 700;
  margin-right: 20px !important;
}

.sign_in_icon {
  font-size: 32px;
}

.home_carousel_div {
  position: relative;
}

.hero_title_div {
  position: absolute;
  width: 500px;
  height: 40px;
  top: 34%;
  left: 30%;
  transform: translate(-50%, -50%);
  z-index: 111;
}

.hero_title {
  color: #fff;
  font-size: 48px;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 3px;
  line-height: 1.4;
  text-align: left;
  z-index: 111 !important;
}

.hero_para_div {
  position: absolute;
  width: 765px;
  height: 40px;
  top: 53%;
  left: 40%;
  transform: translate(-50%, -50%);
  z-index: 111;
}

.hero_para {
  font-size: 15x;
  text-align: left;
  font-weight: 700;
  margin-top: 10px;
  line-height: 140%;
  letter-spacing: 2px;
  color: #fff;
}

.hero_btn_div {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  top: 67%;
  left: 20%;
  transform: translate(-50%, -50%);
  z-index: 111;
}
.hero_btn_div button {
  background-color: #ff770a;
  border: 1px solid #ff770a;
  color: #fff;
  border-radius: 5px;
  width: 220px;
  height: 35px;
  cursor: pointer;
  height: 48px;
  font-size: 16px !important;
  /* z-index: -111111; */
}

.hero_btn_div button:hover {
  background-color: #1bc2df;
  color: #fff;
  border: 2px solid #1bc2df;
}

.hero_btn_learn_more {
  background-color: #fff !important;
  color: #ff770a !important;
}

.hero_btn_learn_more:hover {
  color: #1bc2df !important;
  border: 2px solid #1bc2df;
}

/* Styling Home Intro Section Start */

.home_intro_container_fluid {
  background-color: #f9fafb;
  padding-bottom: 40px;
}

/* Styling Home Intro Section End */

/* Styling Home Trending Section Start */
.home_trending_container_fluid {
  /* padding: 150px 0px; */
  padding-bottom: 30px;
}

/* Styling Home Trending Section End */

.home_service_container_fluid {
  background-color: #f9fafb;
  margin: 100px;
  margin-top: 0px !important;
}

/* Styling Home Testimonial Section Start */

.home_testimonial_container_fluid {
  background-color: #f9fafb;
}

/* Styling Home Testimonial Section End */

/* Styling Home project Section Start */

/* .home_project_container_fluid {
  background-color: #f9fafb;
} */

/* Styling Home project Section End */

/* Styling Banner Section  */

.home_banner_container_fluid {
  background-color: #ffa862;
  /* padding: 40px; */
}

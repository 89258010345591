/* .profile_page_div {
  margin-top: 80px;
  background-color: #f9fafb;
} */

.profile_div {
  display: flex;
  flex-direction: column;
}

.profile_page_title {
  font-family: "Inter", sans-serif !important;
  /* font-style: normal; */
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  color: #004a57;
  text-align: left;
}
.profile_image_div {
  text-align: left;
  width: 160px;
  height: 160px;
  object-fit: cover;
  margin-top: 30px;
  margin-bottom: 50px;
}

.profile_image {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.change_div {
  display: flex;
  margin-left: 45px;
  width: 100%;
  margin-top: 8px;
  text-align: center;
  /* padding-bottom: 20px !important; */
}

.edit_icon {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 800;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  margin-right: 4px;
  color: #004a57;
}

.change_text {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  display: flex;
  align-items: center;

  color: #004a57;
}
/* color: #004a57; */

.navbar_signup {
  background-color: #fdfdfd !important;
  height: 63px;
  border-bottom: 1px solid #e7e7e7;
}

.signup_container {
  margin-top: 30px;
}
/* Styling Form Field */

.form_title {
  font-style: normal;
  font-weight: 600;
  font-size: 36px;
  line-height: 40px;
  text-align: left;
  text-transform: uppercase;
  margin-left: 50px;
}

.form_div {
  text-align: left;
}

.submit_btn {
  background-color: #ff770a !important;
  color: #fff !important;
  outline: none !important;
  margin-top: 20px !important;
  margin-bottom: 20px !important;
  width: 50%;
}

.submit_btn:focus {
  outline: none !important;
  box-shadow: none !important;
}

.side_nav {
  position: fixed;
}

.sidebar_div {
  margin-top: 100px;
}

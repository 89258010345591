.home_testimonial_section_container {
  margin-bottom: 20px;
}

.home_testimonial_section {
  padding-top: 10px;
  margin-top: 40px;
  padding-bottom: 30px;
}

.home_testimonial_row {
  padding-top: 50px;
  padding-bottom: 30px;
}

.testimonial_div {
  padding-bottom: 16px;
}

/* Styling Testimonial UI */

.testimonial_card {
  background-color: #fff1e6 !important;
  padding: 10px;
  padding-bottom: 20px;
  box-shadow: 0px 4px 3px rgba(0, 0, 0, 0.04), 0px 10px 8px rgba(0, 0, 0, 0.1);
  border-radius: 12px !important;
}

.location_title {
  font-family: "Manrope" sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: #4b5563;
}

.location_icon {
  margin-right: 5px;
  color: #6b7280;
}

.review_para {
  font-family: "Manrope", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  /* text-align: justify !important; */
  text-align: center;
  color: #1f2937;
  /* padding-top: 15px;
  padding-bottom: 40px; */
  padding: 15px 25px 40px 25px;
}

.card_bottom_content {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  padding-left: 20px;
  /* padding-bottom: 40px; */
}

.card_user_content {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  gap: 2px;
}

.user_name {
  font-family: "Manrope" sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 28px;
  color: #000000;
}

.user_desp {
  font-family: "Manrope" sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 150%;
  letter-spacing: 0.04em;
  color: #4b5563;
}

.card_img_div {
  width: 65px;
  height: 65px;
  border-radius: 50%;
  overflow: hidden;
  border: 4px solid #fff;
}

.card_img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.gender_main_div {
  margin-top: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
  margin-bottom: 100px;
}

.gender_img_div {
  width: 40%;
  height: 200px;
  display: flex;
  justify-content: center;
  gap: 10px;
  align-items: center;
  margin: auto;
}

.gender_img_male {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.gender_img_female {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.gender_top_div {
  display: flex;
  justify-content: space-evenly !important;
}

.skip_btn {
  display: inline !important;
  margin-left: 70%;
  z-index: 111;
}

.choose_title {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 38px;
  line-height: 140%;
}

.choose_para {
  font-family: "Manrope", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
}

.para_bottom{
    font-family: "Manrope", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  color: rgb(83, 83, 83);
  /* opacity: 0.6; */
}

.next_btn {
  display: inline !important;
  margin-left: 70%;
  width: 200px;
  background-color: #ff770a !important;
  border-radius: 5px;
  color: #ffffff !important;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 32px;
  text-align: center;
  z-index: 111;
}

.next_btn:focus {
  outline: none !important;
  box-shadow: none !important;
}
